export enum PrintStatus {
  STATUS_PRINTED = 'printed',
  STATUS_STANDBY = 'standby',
  STATUS_ERROR = 'error',
  STATUS_CANCELED = 'cancel',
}

export enum PrintTypes {
  TYPE_ACCEPTANCE = 'acceptance',
  TYPE_KITCHEN = 'kitchen',
  TYPE_SETTLE = 'settle',
  TYPE_COUNTERFOIL = 'counterfoil',
  TYPE_TESTPRINT = 'test_print',
  TYPE_RECEIPT = 'receipt',
  TYPE_JOURNAL = 'journal',
  TYPE_DRAWER = 'drawer',
}

export enum PrintBit {
  TYPE_ACCEPTANCE = 0b00000001,
  TYPE_KITCHEN = 0b0000010,
  TYPE_SETTLE = 0b00000100,
  TYPE_COUNTERFOIL = 0b00001000,
  TYPE_TESTPRINT = 0b01000000,
  TYPE_RECEIPT = 0b00010000,
  TYPE_JOURNAL = 0b00100000,
  TYPE_SETTLE_AND_RECEIPT = 0b00010100,
//   TYPE_DRAWER is not exist
}
