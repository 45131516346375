




































































import { PrintBit } from "../../model/entity/printJobSetting";
import Vue from "vue";
import store from "../../store/shop";
export default Vue.component("SHHeader", {
  components: {
    SHStaffSelectDialog: () => import("./SHStaffSelectDialog.vue"),
    SHPrinterSelectDialog: () => import("./SHPrinterSelectDialog.vue"),
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isStaffDialogOpen: false,
      isPrinterDialogOpen: false,
      hasManualPrintRule: false,
    };
  },
  computed: {
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    isBackIcon(): boolean {
      const backIcon = ["order", "checkQuick", "check", "history", "cart", "printer", "category", "category_group","setting-last_order","soldout_group","soldout_category"];
      if (backIcon.find((item) => item === this.page)) {
        return true;
      }
      return false;
    },
    canUseTakeout(): boolean {
      return store.getters.canUseTakeout;
    },
    staffName(): string {
      return store.state.staff.nickname ? store.state.staff.nickname : "-";
    },
    printerNames() {
      return store.state.printerName;
    },
    takeoutAlert(): string {
      if (store.state.takeoutCount > 0) {
        return "alert";
      }
    },
    takeoutCount(): number {
      return store.state.takeoutCount;
    },
    soundOrder(): boolean {
      return store.state.sound.order;
    },
    soundTakeout(): boolean {
      return store.state.sound.takeout;
    },
    soundOn(): boolean {
      return store.state.soundOn;
    },
    stylePrintRule(): string {
      if (this.hasManualPrintRule) {
        return "";
      }
      return "display: none;";
    },
  },
  methods: {
    handleClickToggleSideMenu() {
      this.$emit("toggleSideMenu");
    },
    handleClickDialogOpen() {
      this.isStaffDialogOpen = true;
    },
    handleStaffDialogClose() {
      this.isStaffDialogOpen = false;
    },
    handleClickPrinterDialogOpen() {
      this.isPrinterDialogOpen = true;
    },
    handlePrinterDialogClose() {
      this.isPrinterDialogOpen = false;
    },
    getSettleReceiptPrinterName() {
      return this.printerNames[PrintBit.TYPE_SETTLE_AND_RECEIPT] ? this.printerNames[PrintBit.TYPE_SETTLE_AND_RECEIPT].substr(0, 8) : "-";
    },

    handleClickBackButton() {
      this.$emit('back');
    },
    handleClickTakeoutIcon() {
      this.$router.push(`/${this.prefix}/${this.shoppix}/takeout`);
    },
    handleSoundOn: function () {
      if (store.state.soundOn === true) {
        store.commit("setSoundOn", false);
        store.commit("setLatestOrder", "0000000000");
        store.commit("setLatestTakeoutOrder", "0000000000");
      } else {
        store.commit("setSoundOn", true);
        store.commit("setLatestOrder", "9999999999");
        store.commit("setLatestTakeoutOrder", "9999999999");
      }
    },
  },
});
